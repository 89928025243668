import { Close } from '@mui/icons-material';
import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import ButtonLoader from '../../Helpers/buttonLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import { debounce } from '../../services/common.utils';

export const defaultAssetData = {
  name: '',
  amount: 0,
  price: '',
};

const AddAsset = ({ addAsset, setAddAsset, openAdd, onCloseAdd, loading, onSave, updateCoinPrice }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [errors, setErrors] = useState({ name: false, amount: false, price: false });
  const [searchText, setSearchText] = useState('');
  const [assetList, setAssetList] = useState([]);

  // Monitor screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Modal styles
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#212F4C',
      border: '0px #28274D solid',
      minWidth: isMobile ? '250px' : '500px',
    },
  };

  const handleSave = () => {
    const newErrors = {
      name: !addAsset.name,
      amount: !addAsset.amount,
      price: !addAsset.price,
    };
    setErrors(newErrors);

    if (!newErrors.name && !newErrors.amount && !newErrors.price) {
      onSave(addAsset);
    }
  };

  const handleClose = () => {
    setErrors({ name: false, amount: false, price: false });
    onCloseAdd();
  };

  const fetchCoins = async (query) => {
    setAssetList([]);
    const token = Cookies.get('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}coins${query ? '?coin=' + query : ''}`,
        { headers }
      );
      setAssetList(response.data || []);
    } catch (error) {
      console.error('Failed to fetch coins:', error);
    }
  };

  const debouncedFetchCoins = useCallback(
    debounce((query) => fetchCoins(query), 500),
    []
  );

  useEffect(() => {
    if (searchText.trim()) {
      debouncedFetchCoins(searchText.trim());
    } else {
      setAssetList([]);
    }
  }, [searchText, debouncedFetchCoins]);

  const filteredAssets = assetList
  // .filter((asset) =>
  //   asset.coinName.toLowerCase().includes(searchText.toLowerCase())
  // );

  const handleAssetClick = (asset) => {
    setAddAsset((prev) => ({ ...prev, name: asset.coinName }));
    setSearchText('');
    setErrors((prev) => ({ ...prev, name: false }));
    updateCoinPrice(asset.coinSlug);
  };

  return (
    <Modal
      isOpen={openAdd}
      onRequestClose={onCloseAdd}
      contentLabel="Add Asset"
      style={customStyles}
    >
      <div className="flexRow relative" style={{ padding: '16px' }}>
        <span className="username">Add Asset</span>
        <span className="absolute right-2 text-white cursor-pointer" onClick={onCloseAdd}>
          <Close />
        </span>
      </div>
      <hr style={{ margin: '10px 0' }} />
      <div className="input-container px-5 py-5 text-[#3CC8C8]">
        {/* Name Field */}
        <label className="block text-sm mb-2">Name of coin</label>
        <div className="relative">
          <input
            type="text"
            value={addAsset.name}
            placeholder="Search for a coin"
            className={`adduserAsset border ${errors.name ? 'border-red-500' : 'border-[#3CC8C8]'}`}
            onChange={(e) => {
              const inputValue = e.target.value;
              setSearchText(inputValue);
              setAddAsset((prev) => ({ ...prev, name: inputValue }));
              setErrors((prev) => ({ ...prev, name: false }));
            }}
          />
          {searchText && (
            <div
              className="absolute p-1 rounded-xl custom-dropdown-select w-full bg-[#222F4C] h-44 overflow-y-auto scrollbar-none mt-1"
              style={{ top: '100%', left: 0, zIndex: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
              {filteredAssets.map((asset, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 option rounded-xl text-white px-3 py-2.5 mb-1 cursor-pointer bg-[#07142F] hover:bg-[#3CC8C8] hover:text-black"
                  onClick={() => handleAssetClick(asset)}
                >
                  <img src={asset.coinLogo} alt="coinLogo" className="w-7 h-7" />
                  {asset.coinName}
                </div>
              ))}
            </div>
          )}
        </div>
        {errors.name && <p className="text-xs text-red-500">Please enter the coin name</p>}

        {/* Amount Field */}
        <label className="block text-sm mt-4 mb-2">Amount invested</label>
        <input
          type="number"
          value={addAsset.amount}
          placeholder="0"
          className={`adduserAsset border ${errors.amount ? 'border-red-500' : 'border-[#3CC8C8]'}`}
          onChange={(e) => {
            setAddAsset((prev) => ({ ...prev, amount: e.target.value }));
            setErrors((prev) => ({ ...prev, amount: false }));
          }}
        />
        {errors.amount && <p className="text-xs text-red-500">Please enter the amount invested</p>}

        {/* Price Field */}
        <label className="block text-sm mt-4 mb-2">Average price</label>
        <input
          readOnly
          type="text"
          value={addAsset.price}
          placeholder="0.50"
          className="adduserAsset border border-[#3CC8C8]"
        />
      </div>
      <div className="flexRow gap-5 text-white" style={{ padding: '24px' }}>
        {loading ? (
          <ButtonLoader />
        ) : (
          <>
            <button
              onClick={handleSave}
              className="py-3 px-4 w-full rounded-full"
              style={{
                background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)',
              }}
            >
              Add Asset
            </button>
            <button
              onClick={handleClose}
              className="py-3 px-4 w-full bg-[#37445E] rounded-full"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddAsset;
