/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import BarPlot from '../Components/BarPlot'
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import TokenParser from '../Helpers/tokenParser';
import Cookies from 'js-cookie';
import { ChevronRight, ChevronLeft, Reply, Info } from 'lucide-react';
import ButtonLoader from '../Helpers/buttonLoader';
import { Search, EllipsisVertical } from 'lucide-react';
import Dropdown from "../Components/DropDownCheckBoxes";
import filterIcon from '../assets/images/filter.svg'

import Plus from '../assets/plus.svg'
import '../Styles/UserDashboardAdminView.css'
import { filterAndFormatData, formatPhoneNumber, formatToUSCurrency, getFirstName, makeUtcFiveHrsAgo, numericValue } from "../services/common.utils";
import { FaFilter, FaSearch } from "react-icons/fa";
import sampleData from '../Helpers/sampleWalletData'
import graph from '../assets/images/market/graph.svg'
import graphRed from '../assets/images/graphRed.svg'
import WalletActivityHeader from "./Users/WalletActivityHeader";
import { Close } from "@mui/icons-material";
import AddFunds from "../Components/Modal/AddFunds";
import ResetPasswordPopup from "./Users/ResetPasswordPopup";
import InquiryResponse from "./Users/InquiryResponse";
import UpdateBalance from "../Components/Modal/UpdateBalance";
import { toast } from "react-toastify";
import SortInqueries from "./Inquiries/SortInqueries";
import moment from "moment";
import PageLoader from "../Components/Common/PageLoader";
import AssetsBalance from "./Users/AssetsBalance";
import CommonDropdown from "../Components/Common/CommonDropdown";
import WalletTable from "../Components/Wallet/WalletTable";

const UserDashBoardAdminView = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isResetVisible, setIsResetVisible] = useState(false);
    const [isUpdateBalance, setIsUpdateBalance] = useState(false);
    const [currentUser, setCurrentUser] = useState({})
    const [assets, setAssets] = useState([]);
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const userId = pathSegments[pathSegments.length - 1];

    const navigate = useNavigate();
    const [adminName, setAdminName] = useState('');
    const [userName, setUserName] = useState('');
    const [PNLType, setPNLType] = useState("");
    const [totalBalance, setTotalBalance] = useState(0);
    const [updatedAt, setUpdatedAt] = useState("");
    const [previousBalance, setPreviousBalance] = useState("");
    const [openTrades, setOpenTrades] = useState(0);
    const [subscriptionPeriod, setSubscriptionPeriod] = useState(0);
    const [openTrade, setOpenTrade] = useState(false);
    const [loading, setLoading] = useState(false);
    const [walletLoading, setWalletLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);
    const [walletData, setWalletData] = useState([]);
    const [PNL, setPNL] = useState(0);
    const [initialInvestment, setInitialInvestment] = useState(0)

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [recordsFiltered, setRecordsFiltered] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageUser, setCurrentPageUser] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [isActionOpen, setActionOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [addFunds, setAddFunds] = useState('');
    const [fundLoader, setFundLoader] = useState(false)
    const [selectedChartDropdowndOption, setSelectedChartDropdown] = useState('Monthly'); // Default to Monthly
    const [balanceChartType, setBalanceChartType] = useState('recent'); // Default to Monthly
    const [isOpenResponse, setIsOpenResponse] = useState(false)
    const [selectedInquiryData, setSelectedInquiryData] = useState({})
    const recordsPerPage = 5;
    const recordsPerPageUser = 10;
    const [inquirySearch, setInquirySearch] = useState('');
    const [isEdit, setEdit] = useState(false);
    const [chartData, setChartData] = useState([])
    // const userDetails = require('../assets/json/UserDetails.json')
    const [selectedValue, setSelectedValue] = useState("recent");
    // const [chartData, setChartData] = useState([])
    const [selectedType, setSelectedType] = useState('Profit')
    const [isUpdateBalanceLoading, setUpdateBalanceLoading] = useState(false)
    const [withdrawFunds, setWithdrawFunds] = useState(0)
    const [openWithdraw, setOpenWithdraw] = useState(false)
    const [totalPagesUser, setTotalPagesUser] = useState(1);
    const [userDetails, setUserDetails] = useState([]);
    const [sortby, setSortby] = useState('latest');
    const dropdownOptions = [
        { value: 'last transaction', label: 'Last Transaction' },
        { value: 'today', label: 'Today' },
        { value: '1 week', label: 'Last 7 Days' },
        { value: '1 month', label: 'Last 30 Days' },
        { value: 'all time', label: 'All Time' },
    ];

    // control reset pass visibility
    const openResetPopup = () => {
        setIsResetVisible(true);
    };

    const closeResetPopup = () => {
        setIsResetVisible(false);
    };

    // control update balance visibility
    const openUpdateBalance = () => {
        setIsUpdateBalance(true);
    };
    const onCloseUpdate = () => {
        setIsUpdateBalance(false);
    };

    // update balance submit action
    const onUpdateBalanceAction = () => {
        setUpdateBalanceLoading(true)
        let token = Cookies.get('token');
        let headers = {
            Authorization: `Bearer ${token}`
        }
        console.log(currentUser);
        let apiRequest = {
            "userId": userId,
            "subscriptionPeriod": currentUser.subscriptionPeriod ? currentUser.subscriptionPeriod : 0,
            "openTrades": currentUser.openTrades ? currentUser.openTrades : 0,
            type: selectedType,
            amount: currentUser.profitLossAmount ? numericValue(currentUser.profitLossAmount) : 0
        }
        axios.post(`${process.env.REACT_APP_BACKEND}updateBalance`, apiRequest, { headers: headers })
            .then((apiResponse) => {
                fetchDetails('');
                setSelectedType('')
                setUpdateBalanceLoading(false);
                setIsUpdateBalance(false);
                toast.success('Balance update successful', { theme: 'dark' })
            })
            .catch((apiError) => {
                setIsUpdateBalance(false);
                setUpdateBalanceLoading(false);
                toast.error('Balance update failed', { theme: 'dark' })
                if (apiError?.response?.status === 401) {
                    navigate('/login')
                }
            })
    }

    // update balance dropdown change action
    const handleDropdownUpdateBalanceChange = (value) => {
        // setSelectedType(event.target.value)
        setSelectedType(value)
    }

    // 
    // const handleDropdownChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };
    const handleDropdownChange = (value) => {
        setSelectedValue(value);
    };

    // init
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (selectedValue) {
            console.log(selectedValue)
            fetchDetails(selectedValue)
        }
    }, [selectedValue])

    const fetchDetails = async (timeframeValue) => {
        setLoading(true);
        let token = Cookies.get("token");
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        await axios.get(`${process.env.REACT_APP_BACKEND}getUserProfile?timeframe=${timeframeValue}&id=${userId}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                // console.log("apiResponse", apiResponse.data);
                setUserName(apiResponse.data?.username);
                setTotalBalance(apiResponse.data?.balance);
                setPreviousBalance(apiResponse.data?.previousBalance);
                setUpdatedAt(makeUtcFiveHrsAgo(apiResponse.data?.lastUpdatedOn));
                setPNLType(apiResponse.data?.profitLossType);
                setInitialInvestment(apiResponse?.data?.initialInvestmentAmount)
                setSubscriptionPeriod(apiResponse.data?.subscriptionPeriod);
                setOpenTrade(apiResponse.data?.enableTrade);
                setOpenTrades(apiResponse.data?.openTrades);
                setPNL(
                    apiResponse.data?.profitLossAmount
                );
                setEmail(apiResponse?.data?.email);
                setPhoneNumber(apiResponse.data?.phoneNumber);
                setName(apiResponse.data?.username);
                // user object
                setCurrentUser(apiResponse?.data)
                setSelectedType(apiResponse?.data?.profitLossType)
            })
            .catch((apiError) => {
                // toast.error('Failed to fetch user details', { theme: 'dark' })
                if (apiError?.response?.status === 401) {
                    navigate("/login");
                }
            }).finally(() =>
                setLoading(false)
            );

        headers["userId"] = userId;
        await getWalletRecords()
    };

    const start = (currentPage - 1) * recordsPerPage + 0;
    let usertoken = Cookies.get("token");
    let headers = {
        Authorization: `Bearer ${usertoken}`,
    };
    headers["userId"] = userId;

    const fetchChartDetails = async (timeframeValue) => {
        setLoading(true);


        await axios.get(`${process.env.REACT_APP_BACKEND}stats?timeframe=${timeframeValue}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                console.log("apiResponse", apiResponse.data);
            })
            .catch((apiError) => {
                console.log("apiError", apiError.messsage);
                if (apiError?.response?.status === 401) {
                    navigate("/login");
                }
            });

        await getWalletRecords()
    };

    const getWalletRecords = async () => {
        setWalletLoading(true);
        await axios.get(`${process.env.REACT_APP_BACKEND}history?start=${start}`, { headers: headers, })
            .then((apiResponse) => {
                setWalletData(apiResponse.data?.stats?.length > 0 ? apiResponse.data?.stats?.slice(0, 5) : []);
                setRecordsFiltered(apiResponse.data?.recordsFiltered)
                setChartData(filterAndFormatData(apiResponse.data?.stats, balanceChartType))
                setWalletLoading(false);
            })
            .catch((apiError) => {
                if (apiError?.response?.status === 401) {
                    navigate("/login");
                }
                setWalletLoading(false);
            });
    }

    useEffect(() => {
        if (start) {
            getWalletRecords()
        }
    }, [start])

    useEffect(() => {
        let token = Cookies.get("token");
        let details = TokenParser(token);
        let firstLetter = details.payload?.username;
        if (firstLetter) {
            setAdminName(firstLetter);
        }
        fetchDetails();
    }, []);

    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            setCurrentPage(1);
        }
    };

    const handleSave = () => {

    }

    const ActionButtons = ({
        item,
        index
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef(null);

        // Close dropdown when clicking outside
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, []);

        return (
            <div className="relative flex px-2 gap-3 items-center justify-around md:justify-center" ref={dropdownRef}>
                {/* Dropdown Trigger Button */}
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center gap-2 py-2 rounded-full text-white"
                >
                    <EllipsisVertical></EllipsisVertical>
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                    <div className="absolute md:w-fit ms-[-150px] md:ms-0 top-7 mt-2 w-48 bg-[#2A3B59] rounded-lg shadow-lg py-1 z-10 text-white">
                        <div className="flex justify-between items-center px-4 py-2 border-b border-[#28274D]">
                            <span className="text-sm">Action</span>
                        </div>
                        <div className='w-[100%] flex flex-col items-center justify-start md:px-3'>
                            {/* Update Balance Option */}
                            <button
                                onClick={() => handleReplyAction(item)}
                                className="w-[80%] md:w-full text-white mt-2 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                <Reply />
                                Reply
                            </button>

                            {/* Add Funds Option */}
                            {/* <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="w-[80%] text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                View more
                            </button> */}

                            {/* View Option */}
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="w-[80%] md:w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                Resolve Enquiry
                            </button>
                        </div>
                    </div>
                )}


            </div>
        );
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    const handlePageChangeUser = (page) => {
        setCurrentPageUser(page);
    }
    const ClientMessage = ({ content }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div>
                <p className={`truncate-text ${isExpanded ? 'expanded' : ''}`}>
                    {content}
                </p>
                {content?.split(' ')?.length > 20 && ( // Only show "Read More" if content is long
                    <span
                        className="read-more-toggle text-[#33CCCC] cursor-pointer"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? 'Read less' : '... Read more'}
                    </span>
                )}
            </div>
        );
    };

    const onAddFunds = (item, index) => {
        setOpenAdd(true);
    }

    const onCloseAdd = () => {
        setOpenAdd(false);
    }

    let token = Cookies.get('token');

    const onTransactionAction = async (param) => {
        setFundLoader(true)
        let headers = {
            Authorization: `Bearer ${token}`
        }
        if (param) {
            const apiUrl = process.env.REACT_APP_BACKEND + 'transaction';
            const requestBody = {
                userId: userId,
                transactionType: param,
                fund: param === 'credit' ? addFunds : withdrawFunds
            };

            try {
                const response = await axios.post(apiUrl, requestBody, {
                    headers: headers
                });

                if (response?.status === 200) {
                    toast.success('Transaction completed successfully', { theme: 'dark' });

                    if (param === 'debit') {
                        setWithdrawFunds(0)
                        setOpenWithdraw(false)
                    } else {
                        setAddFunds(0)
                        fetchChartDetails(selectedChartDropdowndOption)
                        setFundLoader(false);
                        setOpenAdd(false);
                    }



                } else {
                    toast.error('Failed to complete the transaction. Please try again.', { theme: 'dark' });
                }

                setFundLoader(false)
            } catch (error) {
                toast.error('An error occurred while processing your request. Please try again.', { theme: 'dark' });
            } finally {
                fetchDetails();
            }
        }
    };

    const renderInquiryTableData = () => {

        if (isMobile) {
            // Mobile View
            return (
                <>
                    <div className="flex items-center space-x-4 px-2 py-4 justify-between">
                        <h1 className="text-white text-lg font-semibold">Inquiries</h1>
                        <div className="flex flex-1 justify-end gap-2">
                            <div className="flex items-center space-x-2 bg-[#202F4C] rounded-full px-4 py-2 text-gray-300">
                                <FaSearch />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="bg-transparent outline-none text-gray-300 placeholder-gray-300"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>

                            <button className="flex items-center justify-center w-12 h-12 text-gray-300">
                                <img src={filterIcon} alt="filter" />
                            </button>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {currentUserDetails?.map((item, index) => (
                            <div
                                key={index}
                                className="bg-[#202F4C] p-4 rounded-2xl text-white space-y-2 shadow-md relative"
                            >
                                {/* Actions */}
                                <div className="flex justify-end mt-2">
                                    <ActionButtons item={item} index={index} />
                                </div>
                                {/* Contact Info */}
                                <div className="flex justify-between items-center break-words my-2">
                                    <div className="text-sm">
                                        <div>{item.email}</div>
                                        <div>{item.phone}</div>
                                    </div>
                                    <button className={`${item?.response?.toLowerCase() === 'resolved' ? 'bg-[#33CCCC]' : item.response?.toLowerCase() === 'pending' ? 'bg-[#FD9C35]' : 'bg-red-500'} text-white px-3 py-1 rounded-full capitalize text-sm`}>
                                        {item.response}
                                    </button>
                                </div>

                                {/* Date */}
                                <div className="flex justify-between">
                                    <div className="text-[#33CCCC] text-sm">Date</div>
                                    <div className="text-lg">{item.date}</div>
                                </div>
                                {/* Message */}
                                <div className="text-[#33CCCC] text-sm">Message</div>
                                <div className="text-white">
                                    <ClientMessage content={item?.clientMessage} />
                                </div>


                            </div>
                        ))}
                    </div>
                </>

            );
        }
        else return (
            <div className="mobileTable userDataTable">
                <table className="table table-auto w-full">
                    <thead>
                        <tr>
                            <th className="userDataTableHeader w-1/5 text-left px-4 py-2">Date</th>
                            <th className="userDataTableHeader w-[350px] text-left px-4 py-2">Contact</th>
                            <th className="userDataTableHeader w-[300px] text-center px-4 py-2">Client Message</th>
                            <th className="userDataTableHeader w-[100px] px-4 py-2" style={{ textAlign: 'center' }}>Status</th>
                            <th className="userDataTableHeader w-[100px] text-right px-4 py-2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingUser ? (
                            <tr>
                                <td colSpan="5" className="p-4 text-center">
                                    <div className="skeleton">
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            currentUserDetails?.map((item, index) => (
                                <tr
                                    key={index}
                                    className="border-b border-gray-700 items-center"
                                >
                                    <td
                                        data-label="Date"
                                        className="text-white text-lg px-4 py-2 text-left w-1/5"
                                    >
                                        {item.date}
                                    </td>
                                    <td
                                        data-label="Contact"
                                        className="break-words text-white text-lg px-4 py-2 text-left w-[350px]"
                                    >
                                        {item.email} <br /> {item.phone}
                                    </td>
                                    <td
                                        data-label="Client Message"
                                        className="text-white text-lg px-4 py-2 text-center w-[300px]"
                                    >
                                        <ClientMessage content={item.clientMessage} />
                                    </td>
                                    <td
                                        data-label="Response"
                                        className="text-white text-lg px-4 py-2 text-center w-[100px]"
                                    >
                                        <div className="flex justify-center">
                                            <button className={`capitalize ${item?.response?.toLowerCase() === 'resolved' ? 'bg-[#33CCCC]' : item.response?.toLowerCase() === 'pending' ? 'bg-[#FD9C35]' : 'bg-red-500'} px-4 py-1 rounded-full`}>
                                                {item.response}
                                            </button>
                                        </div>
                                    </td>
                                    <td
                                        data-label="Actions"
                                        className="text-white text-lg px-4 py-2 text-right w-[100px]"
                                    >
                                        <ActionButtons item={item} index={index} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

        );
    };

    const totalPages = Math.ceil(recordsFiltered / recordsPerPage);
    // const totalPagesUser = Math.ceil(userDetails / recordsPerPageUser);

    const renderPaginationUser = () => {
        const buttons = [];

        // Add Previous button if currentPage is greater than 1
        if (currentPageUser > 1) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="prev" onClick={() => handlePageChangeUser(currentPageUser - 1)}>
                    <ChevronLeft color='white' />
                </button>
            );
        }

        // Calculate the starting page number for pagination
        let startPage = 1;
        if (startPage <= 0) {
            startPage = 1;
        }

        // Calculate the ending page number for pagination
        let endPage = Math.ceil(userDetails?.length / recordsPerPageUser);
        if (endPage > totalPagesUser) {
            endPage = totalPagesUser;
        }

        // Render pagination buttons for each page
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button className={`font-medium text-white ${currentPageUser === i ? 'text-[#32CCCB]' : ''}`} key={i} onClick={() => handlePageChangeUser(i)}>{i}</button>
            );
        }

        // Add Next button if currentPage is less than totalPages
        if (currentPageUser < totalPagesUser) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="next" onClick={() => handlePageChangeUser(currentPageUser + 1)}>
                    <ChevronRight color='white' />
                </button>
            );
        }

        return buttons;
    }

    // Render wallet data based on the current page
    const currentRecords = walletData;
    const currentUserDetails = Array.isArray(userDetails)
        ? userDetails.slice((currentPageUser - 1) * recordsPerPageUser, currentPageUser * recordsPerPageUser)
        : [];

    // console.log(currentRecords)

    // Profit Loss Filter States START
    const [selectedFitlerValue, setSelectedFitlerValue] = useState('recent')

    const handleFilterChange = (value) => {
        console.log(value)
        setSelectedFitlerValue(value)
    }

    // fetch inqueries
    const fetchInquiries = async () => {
        setLoadingUser(true);
        try {
            let api = `${process.env.REACT_APP_BACKEND}user_inquiry?userId=${userId}&sortby=${sortby}${searchValue !== '' ? '&searchValue=' + searchValue : ''}&page=${currentPageUser}&recordsPerPage=${recordsPerPageUser}`
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(
                api, { headers: headers }
            );
            const data = await response.data;

            setUserDetails(data?.inquiries?.map(e => {
                return {
                    ...e,
                    updatedOn: e?.updatedAt ? moment(e?.updatedAt).format('YYYY-MM-DD') : 'N/A',
                    email: e?.userEmail,
                    phone: e?.userPhoneNumber,
                    response: e?.status,
                    date: e?.createdAt ? moment(e?.createdAt).format('YYYY-MM-DD') : 'N/A',
                    clientMessage: e?.message
                }
            })); // Assuming the response contains an array of inquiries
            setTotalPagesUser(data?.recordsFiltered / recordsPerPageUser); // Assuming the response contains total pages count
        } catch (error) {
            console.error("Error fetching inquiries:", error);
        } finally {
            setLoadingUser(false);
        }
    };

    useEffect(() => {
        fetchInquiries();
    }, [currentPageUser, searchValue, sortby]);

    useEffect(() => {
        if (selectedFitlerValue) {
            fetchDetails(selectedFitlerValue)
        }
    }, [selectedFitlerValue])
    // Profit Loss Filter States END

    useEffect(() => {
        if (balanceChartType) {
            let filteredData = filterAndFormatData(walletData, balanceChartType)
            console.log(filteredData, 'fit')
            setChartData(filteredData)
        }
    }, [balanceChartType])


    const handleReplyAction = (data) => {
        setIsOpenResponse(true);
        setSelectedInquiryData(data);
    }

    return (
        isOpenResponse ?
            <InquiryResponse data={selectedInquiryData} setIsOpenResponse={setIsOpenResponse} getInquiries={fetchInquiries} /> :
            <>
                {loading && <PageLoader />}
                <div
                    className={`${loading ? 'blur-xs' : ''} transition-all duration-500 gap-0 rounded-2xl`}
                    style={{
                        overflow: 'hidden',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <p className="font-semibold text-2xl capitalize text-white text-center md:text-start">
                        Hello <span className="text-[#3CC8C8]">{getFirstName()}</span>
                    </p>
                    <div className='mt-5 border-b border-b-[#152445] flex flex-col md:flex-row md:gap-5 justify-between items-center w-full bg-[#212F4C] py-5 md:p-5 rounded-t-2xl'>
                        <div className="flex flex-col md:flex-row items-center gap-8 md:gap-16 text-[#FFFFFF] text-2xl font-semibold p-16">
                            <div className="letter-container">{userName && userName[0].toUpperCase()}</div>
                            <span className="capitalize md:text-5xl">{userName}</span>
                        </div>
                        <div className="button-container md:pe-4">
                            <button type="button" className="addFundsBtn text-xs md:text-lg" onClick={onAddFunds}>
                                <img src={Plus} alt="plus" className="h-3 md:h-7" />
                                Add Funds
                            </button>
                            {/* <button type="button" onClick={openResetPopup} className="loginReset text-xs md:text-lg">Password Reset</button> */}
                            <button type="button" onClick={openUpdateBalance} className="loginReset text-xs md:text-lg">Update Balance</button>
                        </div>
                    </div>

                    {/* Add Funds */}
                    <AddFunds
                        addFunds={addFunds}
                        setAddFunds={setAddFunds}
                        openAdd={openAdd}
                        onCloseAdd={onCloseAdd}
                        loading={loading}
                        onSave={() => onTransactionAction('credit')}
                    />

                    {/* Update Balance */}
                    <UpdateBalance
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        openUpdate={isUpdateBalance}
                        onCloseUpdate={onCloseUpdate}
                        onUpdateBalanceAction={onUpdateBalanceAction}
                        selectType={selectedType}
                        handleDropdownChange={handleDropdownUpdateBalanceChange}
                        loading={isUpdateBalanceLoading}
                    />

                    {/* Reset Password */}
                    {isResetVisible && <ResetPasswordPopup onClose={closeResetPopup} />}


                    <div className="mb-5 bg-[#202F4C] p-5 rounded-b-2xl text-white flex flex-col space-y-3 md:space-y-0 md:grid md:grid-cols-4 gap-6">
                        <div className="col-span-4 text-white font-medium text-xl">
                            Balance Info
                        </div>
                        {/* Left Side: Current Balance and Bottom Cards */}
                        <div className="space-y-3 col-span-1">
                            <div className="flex flex-col gap-4 md:h-full p-0">
                                {/* Current Balance Card */}
                                <div className="bg-[#3CC8C8] rounded-2xl p-6 text-center md:flex md:flex-col md:justify-between space-y-4 md:h-full">
                                    <div className="text-sm md:text-md font-medium md:text-start bg-white text-[#666666] py-1 px-3 rounded-full w-fit">Current Balance</div>
                                    <div className="flex flex-col">
                                        <div className="text-4xl font-bold text-[#FFFFFF] md:text-end my-2">{totalBalance}</div>
                                        <div className="text-sm md:text-end">Previous Balance <span className="text-white">{previousBalance}</span></div>
                                    </div>
                                </div>

                                {/* Initial Investment and Profit/Loss */}
                                <div className="flex space-x-4 md:flex-col md:space-x-0 md:space-y-4 md:h-full">
                                    {/* Initial Investment */}
                                    <div className="bg-[#7E6CFF] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col md:justify-between">
                                        <div className="text-sm md:text-md font-medium md:text-start bg-white text-[#666666] py-1 px-3 rounded-full w-fit">Invested Amount</div>
                                        <div className="text-md md:text-4xl font-bold text-[#FFFFFF] md:text-end">{initialInvestment ? formatToUSCurrency(initialInvestment) : 'N/A'}</div>
                                    </div>

                                    {/* Profit or Loss */}
                                    <div className="bg-[#D02AFD] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col md:justify-between">
                                        <div className="text-sm md:text-md font-medium md:text-start bg-white text-[#666666] py-1 px-3 rounded-full w-fit">Profit or Loss</div>
                                        <div className={`text-xs font-bold  md:text-end text-end`}>
                                            <CommonDropdown
                                                options={dropdownOptions}
                                                selectedValue={selectedValue}
                                                onChange={handleDropdownChange}
                                            />
                                        </div>
                                        {/* ${PNL && numericValue(PNL) >= 0 ? 'amount blue' : 'amount red'} */}
                                        <div className={`text-md md:text-xl font-bold  md:text-end text-[#FFFFFF]
                                            }`}>{PNL || 'N/A'}</div>
                                    </div>

                                    {updatedAt &&
                                        <div className="bg-white rounded-full p-3 font-medium text-[#000000] text-center">
                                            Updated on: {updatedAt}
                                        </div>}
                                </div>
                            </div>
                        </div>

                        {/* Center: Bar Plot */}
                        <div className="md:col-span-2 md:flex md:justify-center md:w-full">
                            {isMobile ? <BarPlot height={270} width={320}
                                setSelectedChartDropdown={setBalanceChartType}
                                selectedType={balanceChartType}
                                setChartData={setChartData}
                                data={chartData}
                            /> : <BarPlot height={350} width={750}
                                setSelectedChartDropdown={setBalanceChartType}
                                selectedType={balanceChartType}
                                setChartData={setChartData}
                                data={chartData}
                            />}
                        </div>

                        {/* Right Side: Open Trades and Cycle */}
                        <div className="col-span-1">
                            <div className="flex space-x-4 md:flex-col md:space-x-0 md:space-y-4 md:h-full">
                                <div className="rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col justify-between md:h-full"
                                    style={{
                                        background: 'linear-gradient(240.66deg, #38FFC1 -81.43%, #7770FF 51.35%)'
                                    }}>
                                    <div className="text-sm md:text-lg font-semibold text-start">Open Trades</div>
                                    <div className="text-4xl text-end font-bold text-white">{openTrades || 'NA'}</div>
                                </div>

                                <div className="bg-[#37435E] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col justify-between md:h-full"
                                    style={{
                                        background: 'linear-gradient(93.99deg, #7770FF -257.85%, #3CC8C8 81.62%)'
                                    }}>
                                    <div className="text-sm md:text-lg font-semibold text-start">Cycle</div>
                                    <div className="md:flex md:flex-col">
                                        <div className="text-4xl text-end font-bold text-white">{subscriptionPeriod}</div>
                                        <div className="text-sm text-end text-white/70">days</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 table-form-container rounded-2xl flex-wrap md:flex-nowrap'>
                        <div className='userTable rounded-2xl w-full mb-5 md:mb-0 p-5'>
                            <div className='p-0 bg-[#202F4C] mb-5'>
                                <div className='flexCol' style={{ alignItems: 'flex-start', display: 'flex', gap: '1em' }}>
                                    <WalletActivityHeader />
                                    <div className="table-btn-container">
                                        <button className='AddUserButton'>Recent Transactions</button>
                                        {/* <button className='AddUserButton'>Upcoming Transactions</button> */}
                                    </div>
                                </div>
                            </div>

                            {/* Wallet Table */}
                            <WalletTable
                                currentRecords={currentRecords}
                                isMobile={isMobile}
                                graph={graph}
                                graphRed={graphRed}
                                loading={walletLoading}
                                recordsPerPage={recordsPerPage}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                recordsFiltered={recordsFiltered} />

                        </div>
                        {
                            !isEdit ?
                                <div className="form-container bg-[#202F4C] p-5" style={{ backgroundColor: '#202F4C' }}>
                                    <div className="record-header pe-3 md:pe-0">
                                        <div className="record-title text-md md:text-xl">User Information</div>
                                        <button onClick={() => { setEdit(true) }}
                                            className="py-2 px-4 text-lg text-white rounded-full md:w-[100px]"
                                            style={{
                                                background: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)'
                                            }}
                                        >Edit</button>
                                    </div>
                                    <div className="md:mt-[5rem]">
                                        <div className="details-header  hidden md:block" style={{ color: ' #3CC8C8' }}>
                                            Full Name
                                        </div>
                                        <div className="details-value capitalize" style={{ color: '#3CC8C8' }}>
                                            {name || ''}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="details-header">
                                            Email Address
                                        </div>
                                        <div className="details-value text-md md:text-lg">
                                            {email || ''}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="details-header">
                                            Phone Number
                                        </div>
                                        <div className="details-value text-md md:text-lg">
                                            {formatPhoneNumber(phoneNumber) || ''}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="form-container bg-[#202F4C] p-5" style={{ backgroundColor: '#202F4C' }}>
                                    <div className="record-header">
                                        <div className="record-title text-md md:text-xl">User Information</div>
                                        <button onClick={() => { setEdit(false) }} className="text-slate-300"><Close /></button>

                                        {/* <button className="edit-btn">Edit</button> */}
                                    </div>
                                    <div className="md:h-full md:flex md:flex-col md:justify-center gap-3 md:space-y-6">
                                        <div className='input-container'>
                                            <span className='block text-sm text-[#3CC8C8] mb-2'>FullName</span>
                                            <input type='text' value={name} placeholder="Full Name" className='adduserInput'
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                    setErrorMessage('');
                                                }
                                                }
                                            />
                                        </div>

                                        <div className='input-container'>
                                            <label className="block text-sm text-[#3CC8C8] mb-2">Email Address</label>
                                            <input type="email" value={email} placeholder="Email address" className="adduserInput"
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    setErrorMessage('');
                                                }}
                                            />
                                        </div>

                                        <div className='input-container'>
                                            <label className="block text-sm text-[#3CC8C8] mb-2">Phone Number</label>
                                            <div className="flex">
                                                <select className="adduserInput dropdown">
                                                    <option>+1</option>
                                                </select>
                                                <input type="tel" value={phoneNumber} placeholder="000 123 003" className="adduserInput phone"
                                                    onChange={(e) => {
                                                        setPhoneNumber(e.target.value);
                                                        setErrorMessage('');
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="btn-container mt-5">
                                            {
                                                loading ?
                                                    <div className='AddUserButton'>
                                                        <ButtonLoader />
                                                    </div>
                                                    :
                                                    <div>
                                                        <button onClick={handleSave}
                                                            className='AddUserButton'>Save</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>

                    {/* Assets */}
                    <div className="mt-5">
                        <AssetsBalance assets={assets} setAssets={setAssets} />
                    </div>

                    {/* Inquiries */}
                    <div className="mt-5 inquiry-table-container md:bg-[#202F4C] md:p-5 md:rounded-2xl">
                        <div className="record-header hidden md:flex">
                            <span class="record-title hidden md:block">Inquiries</span>
                            <div className='topfeatures md:items-center'>
                                <div className='relative'>
                                    <Search className='absolute left-3 top-[20px]' size={18} color='#D6DAE0' />
                                    <input
                                        style={{ fontSize: '1rem' }}
                                        className='ps-10 pe-4 text-md py-2 rounded-full text-[#D6DAE0] bg-[#37445E] outline-none'
                                        onKeyDown={handleSearchKeyPress}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        placeholder='Search'
                                    />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <SortInqueries setSortingOption={setSortby} sortingOption={sortby} />
                                </div>
                            </div>
                        </div>

                        {
                            userDetails?.length > 0 ?
                                <>
                                    {renderInquiryTableData()}
                                    <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                                        <div>
                                            <span className='paginationResultText'>
                                                Showing {(currentPageUser - 1) * 10 + 1} to {currentPageUser * 10 > userDetails.length ? userDetails.length : currentPageUser * 10} of {userDetails.length} Inquiries
                                            </span>
                                        </div>
                                        <div className='flexRow' style={{ gap: 16 }}>
                                            {
                                                renderPaginationUser()
                                            }
                                        </div>
                                    </div>
                                </> :
                                <p className="mt-5 w-full flex gap-2 items-center rounded-lg bg-[#37435E] px-6 py-5 text-base text-info-800 text-white">
                                    <Info />
                                    <span>No Inquiries Found</span>
                                </p>
                        }

                    </div>
                </div>
            </>
    );
};

export default UserDashBoardAdminView;
