import React, { useEffect, useState } from 'react'
import TokenParser from '../Helpers/tokenParser';
import Cookies from 'js-cookie';
import QuickAnalytics from '../Components/Analytics/QuickAnalytics';
import ActiveTradeStats from '../Components/ActiveTrades/ActiveTradeStats';
import UsersList from './Users/UsersList';
import { calculateTotals } from '../services/common.utils';
import axios from 'axios';

function DashboardHome() {
    let token = Cookies.get("token");
    const [analyticsData, setAnalyticsData] = useState(null);

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false)

    const [analyticsStat, setAnalyticsStat] = useState({
        totalUsers: { count: 0, growth: 0 },
        pendingInquiries: { count: 0, growth: 0 },
        tradingUsers: { count: 0 },
    });
    const [selectedValue, setSelectedValue] = useState("today");

    const [cardsData, setCardData] = useState([
        {
            id: 'balance',
            title: 'Balance',
            value: '$0',
            change: '0',
            bgColor: 'bg-[#3CC8C8]',
            textColor: 'text-black',
            titleColor: '#666666',
            onClick: () => console.log('Volume card clicked'),
            isActive: true
        },
        {
            id: 'totalProfitLoss',
            title: 'Total Profit / Loss',
            value: '$0',
            change: '0',
            bgColor: 'bg-[#7E6CFF]',
            textColor: 'text-white',
            titleColor: '#fff',
            onClick: () => console.log('Revenue card clicked'),
            hasFilter: true,
            selectedValue: 'today',
        },
        {
            id: 'openTrades',
            title: 'Open Trades',
            value: '0',
            days: null,
            bgColor: 'bg-[#D02AFD]',
            textColor: 'text-white',
            titleColor: '#fff',
            onClick: () => console.log('Users card clicked'),
        },
    ]);

    useEffect(() => {
        let token = Cookies.get('token');
        let details = TokenParser(token);
        let firstLetter = details.payload?.username;
        if (firstLetter) {
            setName(firstLetter)
        }
    }, [])

    // update Analytics/Card data
    const updateStat = (data) => {
        let modified_data = {
            ...analyticsStat,
            totalUsers: {
                count: data?.recordsFiltered,
                growth: 0
            },
        }
        setAnalyticsStat(modified_data)

        const totals = calculateTotals(data?.users);

        // setCardData((prevData) =>
        //     prevData.map((card) => {
        //         if (card.title === 'Total Trades') {
        //             return { ...card, value: `$${totals.totalBalance.toFixed(2)}` };
        //         } else if (card.title === 'Total Profit / Loss') {
        //             return { ...card, value: `$${totals.totalProfitLoss.toFixed(2)}` };
        //         } else if (card.title === 'Open Trades') {
        //             return { ...card, value: totals.totalOpenTrades.toString() };
        //         }
        //         return card;
        //     })
        // );
    }

    useEffect(() => {
        if (cardsData?.length > 0) {
            const updatedCardsData = cardsData.map((card) => {
                // update based on`id` with the analytics data key
                switch (card.id) {
                    case 'balance':
                        return {
                            ...card,
                            value: analyticsData?.totalTrades,
                            change: analyticsData?.tradesPctLastTimeFrame,
                        };
                    case 'totalProfitLoss':
                        return {
                            ...card,
                            value: analyticsData?.profileLossByTimeFrame,
                            change: analyticsData?.profitLossPctLastMonth,
                        };
                    case 'openTrades':
                        return {
                            ...card,
                            value: analyticsData?.totalOpenTrades,
                        };
                    default:
                        return card;
                }
            });

            setCardData(updatedCardsData);
        }
    }, [cardsData]);

    useEffect(() => {
        if (analyticsData) {
            // Update analyticsStat
            setAnalyticsStat((prevStat) => ({
                ...prevStat,
                totalUsers: {
                    count: analyticsData?.totalUsers,
                },
                pendingInquiries: {
                    count: analyticsData?.pendingInquiries,
                },
                tradingUsers: {
                    count: analyticsData?.tradingUsers,
                },
            }));
        }
    }, [analyticsData])

    useEffect(() => {
        if(cardsData?.[1]?.selectedValue){
            // fetchAnalyticsData(cardsData?.[1]?.selectedValue)
            setSelectedValue(cardsData?.[1]?.selectedValue)
        }
    },[cardsData?.[1]?.selectedValue])


    // Function to fetch data from API
    const fetchAnalyticsData = async (timeframe = null) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await axios.get(process.env.REACT_APP_BACKEND + "analytics?timeframe=" + (timeframe ? timeframe : selectedValue), {
                headers: headers,
            });
            setAnalyticsData(response.data);
        } catch (error) {
            console.error("Error fetching analytics data:", error);
        }
    };

    const handleDropdownStatChange = (id, newValue) => {
        // setSelectedValue(newValue)
        setCardData((prevCards) =>
            prevCards.map((card) =>
                card.id === id
                    ? { ...card, selectedValue: newValue }
                    : card
            )
        );
    };

    // useEffect to call the fetch function on component mount
    useEffect(() => {
        if (selectedValue) {
            fetchAnalyticsData();
        }
    }, [selectedValue]);

    // render
    return (
        <div className='h-full'>
            <p className='font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{name}</span>
            </p>

            {/* Charts / Active Trades */}
            <div className="grid grid-cols-1 md:grid-cols-10 gap-5 mt-10">
                <div className="md:col-span-7 col-span-1">
                    {/* QuickAnalytics */}
                    <QuickAnalytics
                        // chartData={chartData}
                        stats={cardsData}
                        analyticsStat={analyticsStat}
                        handleDropdownStatChange={handleDropdownStatChange}
                    />
                </div>

                <div className="md:col-span-3 col-span-1">
                    {/* ActiveTradeStats */}
                    <ActiveTradeStats
                        totalTrades={cardsData.find(e => e.id === 'balance')?.value}
                        tradePercent={cardsData.find(e => e.id === 'balance')?.change}
                    />
                </div>
            </div>

            {/* Users */}
            <div className='mt-10'>
                <UsersList updateAnalyticsStat={updateStat} fetchAnalyticsData={fetchAnalyticsData} />
            </div>

        </div>
    )
}

export default DashboardHome